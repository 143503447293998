.main-wrapper {
  margin: 10%;
  .section-title {
    margin-bottom: 60px;
    .title {
      border-bottom: 3px solid var(--color-primary);
      padding-bottom: 15px;
    }
  }
  h4 {
    margin-bottom: 16px;
    font-size: 28px;
  }
  p {
    line-height: 1.7;
    margin-bottom: 48px;
  }
  ul {
    padding-left: 20px;
    margin-bottom: 40px;
    li {
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 28px;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}
