.spr__video {
  width: 120%;

  @media (--mediaLaptop) {
    & {
      width: 150%;
    }
  }

  @media (--mediaTablet) {
    & {
      width: 100%;
    }
  }
}

.spr__earth .earth__viewport {
  position: sticky;
  top: 0;
}

.spr__sidebar-images {
  display: grid;
  grid-template-columns: repeat(6, [col] 1fr);
  align-items: center;

  @media (--mediaTablet) {
    & {
      padding: 0 80px;
      margin-top: 60px;
    }
  }

  @media (--mediaMobile) {
    & {
      padding: 0 20px;
      margin-top: 40px;
    }
  }
}

.spr__sidebar-image {
  &:first-child {
    grid-column: col 1 / span 4;
    grid-row: 1;
    position: relative;
    top: 5%;
    opacity: 0.4;
  }

  &:last-child {
    grid-column: col 3 / span 4;
    grid-row: 1;
    position: relative;
    top: -5%;
  }
}
