.displacement-sphere {
  position: absolute;
  width: 6vw;
  inset: 0;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 3s;
  transition-timing-function: var(--bezierFastoutSlowin);
}

.displacement-sphere--entering,
.displacement-sphere--entered {
  opacity: 1;
}
