.project-add-banner {
  width: 100%;

  display: flex;
  align-items: center;
  margin-bottom: 60px;
  background-color: rgb(var(--rgbAccent));
  margin-bottom: 200px;
  margin-top: 120px;
  border-radius: 30px;
  padding: 0;
  @media (--mediaTablet) {
    & {
      flex-direction: column-reverse;
    }
  }
}

.thumbnail {
  flex: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  height: 80vh;
  max-width: 100%;
  border-radius: 0 30px 30px 0;
  align-items: center;
  overflow: hidden;

  img {
    height: 400px;
    width: 600px;

    transition: all 0.8s ease-in-out;

    object-fit: cover;
    border-radius: 0 30px 30px 0;
  }
  img:hover {
    transform: scale(1.1);
  }
  @media (--mediaTablet) {
    & {
      height: 100%;
      border-radius: 30px;
      width: 100%;

      img {
        border-radius: 30px;
        width: 100%;
      }
      margin: 0px;
    }
  }
  @media (--mediaMobile) {
    & {
      img {
        height: 300px;
        width: 300px;
      }
    }
  }
}
.content {
  flex: 1;

  margin: 0px;

  @media (--mediaTablet) {
    & {
      margin: 0px;
      margin-top: 40px;
    }
  }
}
.project-add-banner--reverse {
  @extend .project-add-banner;

  flex-direction: row-reverse;
  .thumbnail {

    border-radius: 30px 0px 0px 30px;
    img {
      border-radius: 30px;
      width: 100%;
      height: 100%;
    }

    
    @media (--mediaTablet) {
      & {
        height: 100%;
        border-radius: 30px;
        width: 100%;

        img {
          border-radius: 30px;
        }
        margin: 0px;
      }
    }
    @media (--mediaMobile) {
      & {
        img {
          height: 300px;
          width: 300px;
        }
      }
    }
  }

  @media (--mediaTablet) {
    & {
      flex-direction: column-reverse;
    }
  }
}
