.endFooter {
  width: 100vw;
  height: 500px;
  background-color: rgb(var(--rgbAccent));
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .footer-row {
    position: absolute;
    bottom: 8px;
    a {
      text-decoration: none;
      color: black;
      font-size: 12px;
      margin: 0px 8px;
    }
  }
  @media (--mediaTablet) {
    & {
      padding: 20px;
      h3 {
        display: none;
      }
    }
  }
}
