.clientBox {
    margin: 2em 2em;
    outline: none;
    cursor: default;
    display: flex;
    padding: 48px;
    align-items: center;
    flex-direction: column;
    justify-content: top;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition-duration: 300ms;
    background-color: var(--clientTileColor);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
    transition-timing-function: var(--bezierFastoutSlowin);
    @media (--mediaTablet) {
      & {
        width: 90%;
        margin: 1em;  padding: 48px 20px;
  
      }
    }
  }
  .clientBox::after {
    position: absolute;
    inset: 0;
  }
  .clientBox:hover {
    transform: scale(1.05);
  }
  
  .clientBox:active {
    transform: scale(1);
  }