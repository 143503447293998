.productionStrategy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 0 80px 0;
  padding: 1em; h1 {
    font-size: 32px;
  }
  @media (--mediaTablet) {
    & {
      width: 100%;
      h1 {
        font-size: 28px;
      }
    }
  }
  .productionStrategy_decoderText {
    width: 100%;
    margin: 120px;

    font-size: 3em;
    @media (--mediaTablet) {
      & {
        font-size: 2em;
        margin: 64px 32px;
      }
    }
  }
}
