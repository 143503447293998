.our-services-section {
  padding-top: 60px;
  background-color: #111111;
  @media (--mediaMobile) {
    & {
display: flex;
flex-direction: column;
width: 100%;
align-items: center;    }
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  margin: 100px;
  @media (--mediaTablet) {
    & {
      margin: 32px;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
}

.axil-btn {
  display: inline-block;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;

  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.btn-fill-white {
  color: #000;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.brand-logo img {
  max-width: 100%;
  height: auto;
}


