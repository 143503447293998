@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
.monogram {
  fill: var(--colorTextTitle);
  transition: fill var(--durationL) ease;
}

.monogram__highlight {
  fill: rgb(var(--rgbAccent));
  opacity: 0;
  transform: scale3d(1, 0, 1);
  transform-origin: top;
  transition: opacity 0.1s ease var(--durationM)
}

@media (prefers-reduced-motion: no-preference) {
    .monogram__highlight {
      transition: transform var(--durationM) var(--bezierFastoutSlowin),
        opacity 0.1s ease var(--durationM);
    }
  }

a:focus .monogram__highlight,
a:hover .monogram__highlight,
.monogram:hover .monogram__highlight {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transform-origin: bottom;
  transition: opacity 0.1s ease
}

@media (prefers-reduced-motion: no-preference) {
    a:focus .monogram__highlight, a:hover .monogram__highlight, .monogram:hover .monogram__highlight {
      transition: transform var(--durationM) var(--bezierFastoutSlowin), opacity 0.1s ease;
    }
  }

.icon {
  display: block;
  fill: currentColor;
}

.visually-hidden {
  position: absolute;
}

.visually-hidden--hidden,
.visually-hidden--show-on-focus:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  word-wrap: normal;
}

.loader {
  display: flex;
  justify-content: center;
  width: var(--size);
  height: var(--size);
}

.loader-text {
  color: inherit;
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightMedium);
}

.loader__content {
  display: grid;
  grid-template-columns: repeat(3, var(--spanSize));
  grid-gap: var(--gapSize);
  gap: var(--gapSize);
  align-items: center;
  justify-content: center;
  transform: skewX(22deg);
}

@keyframes loader-span {
  0% {
    transform: scaleY(0);
    opacity: 0.5;
    transform-origin: top;
  }
  40% {
    transform: scaleY(1);
    opacity: 1;
    transform-origin: top;
  }
  60% {
    transform: scaleY(1);
    opacity: 1;
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(0);
    opacity: 0.5;
    transform-origin: bottom;
  }
}

.loader__span {
  height: 60%;
  background: currentColor;
  animation: loader-span 1s var(--bezierFastoutSlowin) infinite;
  transform: scaleY(0);
  transform-origin: top left
}

.loader__span:nth-child(2) {
    animation-delay: 0.1s;
  }

.loader__span:nth-child(3) {
    animation-delay: 0.2s;
  }

.button {
  --buttonSize: calc((56 / 16) * 1rem);
  --buttonFontSize: calc((18 / 16) * 1rem);
  --buttonFocusWidth: 4px;
  --buttonPadding: 0 var(--spaceL);
  --buttonTextColor: rgb(var(--rgbBackground));
  --buttonTextVisibility: visible;

  height: var(--buttonSize);
  padding: var(--buttonPadding);
  cursor: pointer;
  transition-property: opacity, color, background;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);
  display: inline-flex;
  align-items: center;
  color: var(--buttonTextColor);
  position: relative;
  z-index: 1
}

.button:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
  }

.button::after {
    content: '';
    transition-property: opacity, color, background;
    transition-duration: var(--durationM);
    transition-timing-function: var(--bezierFastoutSlowin);
    background: rgb(var(--rgbPrimary));
    
    position: absolute;
    inset: 0;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
  }

.button:active {
    box-shadow: none;
    transition-duration: calc(var(--durationXS) / 2);
  }

@media (prefers-reduced-motion: no-preference) {
    .button {
      transition-property: transform, opacity, color, background;
    }

    .button:hover {
      transform: scale(1.05);
    }

    .button:active {
      transform: scale(1);
    }
  }

.button--secondary {
  --buttonSpace: 10px;
  --buttonTextColor: rgb(var(--rgbPrimary));

  background: none;
  padding-left: var(--buttonSpace);
  padding-right: var(--buttonSpace);
  position: relative;
  left: calc(var(--buttonSpace) * -1);
  height: calc((32 / 16) * 1rem)
}

.button--secondary::after {
    content: '';
    height: calc((32 / 16) * 1rem);
    position: absolute;
    inset: 0;
    background-color: rgb(var(--rgbPrimary) / 0.2);
    transform: scale3d(0, 1, 1);
    transform-origin: right;
    -webkit-clip-path: none;
            clip-path: none;
  }

.button--secondary:hover {
    transform: none;
    background: transparent;
  }

.button--secondary:hover::after {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }

@media (prefers-reduced-motion: no-preference) {
    .button--secondary::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

.button--loading {
  --buttonTextVisibility: hidden;
}

.button--icon-only {
  --buttonPadding: 0;
  --buttonTextColor: var(--colorTextBody);

  width: var(--buttonSize);
  align-items: center;
  justify-content: center;
  padding: 0
}

.button--icon-only:hover {
    transform: none;
  }

.button--icon-only::after {
    background: rgb(var(--rgbText) / 0);
  }

.button--icon-only:hover::after {
    background: rgb(var(--rgbText) / 0.1);
  }

.button__text {
  font-size: var(--buttonFontSize);
  font-weight: var(--fontWeightMedium);
  visibility: var(--buttonTextVisibility);
  position: relative;
  line-height: 1;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(var(--rgbBackground));
}

.button__icon {
  transition-property: opacity, fill;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin)
}

@media (prefers-reduced-motion: no-preference) {
    .button__icon {
      transition-property: transform, opacity, fill;
    }
  }

.button__icon--start {
  margin-right: var(--spaceS);
}

.button__icon--end {
  margin-left: var(--spaceS);
}

@media (prefers-reduced-motion: no-preference) {
  .button:hover .button__icon--shift {
    transform: translate3d(var(--spaceXS), 0, 0);
  }
}

.button--loading .button__icon {
  opacity: 0;
}
.footerButton {
  --buttonSize: calc((56 / 16) * 1rem);
  --buttonFontSize: calc((18 / 16) * 1rem);
  --buttonFocusWidth: 4px;
  --buttonPadding: 0 var(--spaceL);
  --buttonTextColor: rgb(var(--rgbBackground));
  --buttonTextVisibility: visible;

  height: var(--buttonSize);
  padding: var(--buttonPadding);
  cursor: pointer;
  transition-property: opacity, color, background;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);
  display: inline-flex;
  align-items: center;
  color: white;
  position: relative;
  z-index: 1
}
.footerButton:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
  }
.footerButton::after {
    content: '';
    transition-property: opacity, color, background;
    transition-duration: var(--durationM);
    transition-timing-function: var(--bezierFastoutSlowin);
    background: var(--footerButtonColor);
    
    position: absolute;
    inset: 0;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
  }
.footerButton:active {
    box-shadow: none;
    transition-duration: calc(var(--durationXS) / 2);
  }
@media (prefers-reduced-motion: no-preference) {
    .footerButton {
      transition-property: transform, opacity, color, background;
    }

    .footerButton:hover {
      transform: scale(1.05);
    }

    .footerButton:active {
      transform: scale(1);
    }
  }
.nav-toggle {
  --buttonSize: var(--space2XL);

  position: relative;
  right: var(--spaceOuter);
  width: var(--buttonSize);
  height: var(--buttonSize);
  z-index: 1024;
  display: none
}

@media (max-width: 696px), (max-height: 696px) {
    .nav-toggle {
      display: flex;
    }
  }

.nav-toggle__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.nav-toggle__icon {
  --iconSize: var(--spaceXL);

  position: absolute;
  transition-property: opacity, fill;
  transition-duration: var(--durationM);
  transition-timing-function: var(--bezierFastoutSlowin);
  opacity: 1;
  transform: rotate(0deg);
  fill: var(--colorTextBody);
  width: var(--iconSize);
  height: var(--iconSize)
}

@media (prefers-reduced-motion: no-preference) {
    .nav-toggle__icon {
      transition-delay: 0.1s;
      transition-property: opacity, transform, fill;
    }
  }

.nav-toggle__icon--open {
  opacity: 0
}

@media (prefers-reduced-motion: no-preference) {
    .nav-toggle__icon--open {
      transition-delay: 0s;
      transform: rotate(45deg);
    }
  }

.nav-toggle__icon--close {
  opacity: 0
}

@media (prefers-reduced-motion: no-preference) {
    .nav-toggle__icon--close {
      transition-delay: 0s;
      transform: rotate(-45deg);
    }
  }

.nav-toggle__icon--open.nav-toggle__icon--close {
  opacity: 1
}

@media (prefers-reduced-motion: no-preference) {
    .nav-toggle__icon--open.nav-toggle__icon--close {
      transition-delay: 0.1s;
      transform: rotate(0deg);
    }
  }

.theme-toggle {
  --buttonSize: var(--space2XL);

  z-index: 2048;
  width: var(--buttonSize);
  height: var(--buttonSize);
  transform: translate3d(0, 0, 0)
}

@media (max-width: 696px), (max-height: 696px) {
    .theme-toggle:not(.theme-toggle--mobile) {
      display: none;
    }
  }

.theme-toggle--mobile {
  position: fixed;
  margin-top: calc(var(--spaceS) * -1);
  margin-right: calc(var(--spaceS) * -1);
  top: unset;
  bottom: var(--spaceXL);
  right: var(--spaceXL);
  margin-top: 0;
  margin-right: 0;
}

.theme-toggle__svg {
  display: block;
}

.theme-toggle__circle {
  fill: currentColor;
  transform: scale(0.6);
  transform-origin: center
}

@media (prefers-reduced-motion: no-preference) {
    .theme-toggle__circle {
      transition-property: transform, fill;
      transition-duration: var(--durationL);
      transition-delay: 0s;
      transition-timing-function: var(--bezierFastoutSlowin);
    }
  }

.theme-toggle__circle--mask {
  fill: white;
}

.theme-toggle__circle--dark {
  transform: none;
  transition-delay: 0.3s;
}

.theme-toggle__mask {
  fill: black;
  transform: translate3d(100%, -100%, 0)
}

@media (prefers-reduced-motion: no-preference) {
    .theme-toggle__mask {
      transition: transform var(--durationL) var(--bezierFastoutSlowin);
      transition-delay: 0s;
    }
  }

.theme-toggle__mask--dark {
  transform: none;
  transition-delay: 0.3s;
}

.theme-toggle__path {
  stroke: currentColor;
  fill: none;
  stroke-linecap: round;
  stroke-width: 3;
  stroke-dasharray: 7 7;
  stroke-dashoffset: 0;
  opacity: 1
}

@media (prefers-reduced-motion: no-preference) {
    .theme-toggle__path {
      transition-property: stroke-dashoffset, opacity;
      transition-duration: var(--durationL);
      transition-timing-function: var(--bezierFastoutSlowin);
      transition-delay: 0.3s;
    }
  }

.theme-toggle__path--dark {
  stroke-dashoffset: 7;
  transition-delay: 0s;
  opacity: 0;
}

.navbar {
  --headerNavFontSize: calc((16 / 16) * 1rem);
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: absolute;
  top: var(--spaceOuter);
  padding-left: var(--spaceOuter);
  padding-right: var(--spaceOuter);
  z-index: 1024
}

@media (max-width: 696px) {
    .navbar {
      --headerNavFontSize: calc((22 / 16) * 1rem);
    }
  }

@media (max-width: 696px), (max-height: 696px) {
    .navbar {
      bottom: auto;
    }
  }

.navbar__logo {
  width: 100%;

  display: flex;
  align-items: center;
  position: relative;
  padding: var(--spaceS) var(--spaceS) var(--spaceL);
  z-index: 16
}

.navbar__logo:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.navbar__logo:active {
    box-shadow: none;
  }

@media (max-width: 696px) {
    .navbar__logo {
      padding-bottom: var(--spaceS);
    }
  }

.navbar__nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end
}

@media (max-width: 696px), (max-height: 696px) {
    .navbar__nav {
      display: none;
    }
  }

.navbar__nav-list {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.navbar__nav-link {
  padding: var(--spaceM);
  color: rgb(var(--rgbText) / 0.8);
  font-weight: var(--fontWeightMedium);
  font-size: var(--headerNavFontSize);
  text-decoration: none;
  position: relative;
  transition: color var(--durationS) ease 0.1s;
  line-height: 1
}

.navbar__nav-link:hover,
  .navbar__nav-link:active,
  .navbar__nav-link:focus,
  .navbar__nav-link[aria-current='page'] {
    color: var(--colorTextBody);
  }

.navbar__nav-link::after {
    content: '';
    position: absolute;
    top: 50%;
    right: var(--spaceS);
    left: var(--spaceS);
    height: 4px;
    background: rgb(var(--rgbAccent));
    transform: scaleX(0) translateY(-2px);
    transform-origin: right;
  }

@media (prefers-reduced-motion: no-preference) {
    .navbar__nav-link::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

.navbar__nav-link:hover:after,
  .navbar__nav-link:active:after,
  .navbar__nav-link:focus:after,
  .navbar__nav-link[aria-current='page']:after {
    transform: scaleX(1) translateY(-2px);
    transform-origin: left;
  }

.navbar__nav-link:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.navbar__nav-link:active {
    box-shadow: none;
  }

.navbar__nav-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 16
}

@media (max-width: 696px), (max-height: 696px) {
    .navbar__nav-icons {
      flex-direction: row;
      position: absolute;
      bottom: var(--spaceXL);
      left: var(--spaceXL);
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .navbar__nav-icons {
      left: var(--spaceL);
      transform: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

.navbar__nav-icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colorTextLight);
  transition: color var(--durationM) var(--bezierFastoutSlowin)
}

.navbar__nav-icon-link:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.navbar__nav-icon-link:active {
    box-shadow: none;
  }

.navbar__nav-icon-link:hover,
  .navbar__nav-icon-link:focus,
  .navbar__nav-icon-link:active {
    color: rgb(var(--rgbAccent));
  }

.navbar__nav-icon {
  fill: currentColor;
  transition: fill var(--durationM) ease;
}

.navbar__mobile-nav {
  position: fixed;
  inset: 0;
  background: rgb(var(--rgbBackground) / 0.9);
  transform: translate3d(0, -100%, 0);
  transition-property: background;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px)
}

@media (max-width: 696px), (max-height: 696px) {
    .navbar__mobile-nav {
      display: flex;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .navbar__mobile-nav {
      transition-property: transform, background;
      transform: translate3d(0, -100%, 0);
    }
  }

.navbar__mobile-nav--entered {
  transform: translate3d(0, 0, 0);
}

.navbar__mobile-nav-link {
  width: 100%;
  font-size: var(--headerNavFontSize);
  text-align: center;
  text-decoration: none;
  color: var(--colorTextBody);
  padding: var(--spaceL);
  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);
  position: relative;
  top: calc(var(--spaceM) * -1)
}

@media (prefers-reduced-motion: no-preference) {
    .navbar__mobile-nav-link {
      transition-property: transform, opacity;
      transform: translate3d(0, calc(var(--spaceXL) * -1), 0);
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .navbar__mobile-nav-link {
      top: auto;
    }
  }

@media (max-width: 400px), (max-height: 360px) {
    .navbar__mobile-nav-link {
      --headerNavFontSize: calc((18 / 16) * 1rem);
    }
  }

.navbar__mobile-nav-link::after {
    content: '';
    position: absolute;
    top: 50%;
    right: var(--space3XL);
    left: var(--space3XL);
    height: 4px;
    background: rgb(var(--rgbAccent));
    transform: scaleX(0) translateY(-1px);
    transform-origin: right;
  }

@media (prefers-reduced-motion: no-preference) {
    .navbar__mobile-nav-link::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

.navbar__mobile-nav-link:hover:after,
  .navbar__mobile-nav-link:active:after,
  .navbar__mobile-nav-link:focus:after {
    transform: scaleX(1) translateY(-1px);
    transform-origin: left;
  }

.navbar__mobile-nav-link:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.navbar__mobile-nav-link:active {
    box-shadow: none;
  }

.navbar__mobile-nav-link--entered {
  opacity: 1;
  transform: none;
}

body {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

input,
textarea {
  font-family: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

button {
  margin: 0;
  border: 0;
  font-family: inherit;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding-left: 1em;
}

body {
  font-family: var(--fontStack);
  font-weight: var(--fontWeightRegular);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  background: rgb(var(--rgbBackground));
  color: var(--colorTextBody);
  border: 0;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

::selection {
  background: rgb(var(--rgbAccent));
  color: rgb(var(--rgbBlack));
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app {
  width: 100%;
  position: relative;
  background: rgb(var(--rgbBackground));
  transition: background var(--durationM) ease;
  outline: none;
  display: grid;
  grid-template: 100% / 100%;
}

.app__page {
  opacity: 0;
  grid-area: 1 / 1;
  transition: opacity var(--durationS) ease;
  min-height: 100vh;
}

.app__page--exiting,
.app__page--entering {
  opacity: 0;
}

.app__page--entered {
  transition-duration: var(--durationL);
  transition-delay: var(--durationXS);
  opacity: 1;
}

.skip-to-main {
  color: rgb(var(--rgbBackground));
  z-index: 128
}

.skip-to-main:focus {
    padding: var(--spaceS) var(--spaceM);
    position: fixed;
    top: var(--spaceM);
    left: var(--spaceM);
    text-decoration: none;
    font-weight: var(--fontWeightMedium);
    line-height: 1;
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.skip-to-main::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgb(var(--rgbPrimary));
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
    z-index: -1;
  }

.endFooter{width:100vw;height:500px;background-color:rgb(var(--rgbAccent));display:flex;align-items:center;flex-direction:column;justify-content:center;position:relative}.endFooter .footer-row{position:absolute;bottom:8px}.endFooter .footer-row a{text-decoration:none;color:#000;font-size:12px;margin:0px 8px}@media(max-width: 1024px){.endFooter{padding:20px}.endFooter h3{display:none}}
.main-wrapper{margin:10%}.main-wrapper .section-title{margin-bottom:60px}.main-wrapper .section-title .title{border-bottom:3px solid var(--color-primary);padding-bottom:15px}.main-wrapper h4{margin-bottom:16px;font-size:28px}.main-wrapper p{line-height:1.7;margin-bottom:48px}.main-wrapper ul{padding-left:20px;margin-bottom:40px}.main-wrapper ul li{margin-top:10px;margin-bottom:10px;line-height:28px;transition:all .3s ease-in-out}.main-wrapper ul li:hover{color:var(--color-primary)}
@keyframes image-reveal {
  0% {
    transform: scale3d(0, 1, 1);
    transform-origin: left;
  }
  49% {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }
  50% {
    transform: scale3d(1, 1, 1);
    transform-origin: right;
  }
  100% {
    transform: scale3d(0, 1, 1);
    transform-origin: right;
  }
}

.image {
  position: relative;
  transform: translate3d(0, 0, 0);
  display: grid;
  grid-template-columns: 100%;
}

.image--raised {
  box-shadow: 0 50px 100px -20px rgb(var(--rgbBlack) / 0.25),
    0 30px 60px -30px rgb(var(--rgbBlack) / 0.3);
}

.image--reveal:not(.image--in-viewport) {
    box-shadow: none;
  }

.image--reveal::before {
    content: '';
    background-color: rgb(var(--rgbAccent));
    position: absolute;
    inset: 0;
    transform: scale3d(0, 1, 1);
    transform-origin: left;
    will-change: transform;
    z-index: 16;
  }

@media (prefers-reduced-motion: no-preference) {
    .image--reveal.image--in-viewport::before {
      animation: image-reveal 1.8s var(--bezierFastoutSlowin) var(--delay);
    }
  }

.image-container {
  position: relative;
  transform: none;
  display: grid;
  grid-template-columns: 100%;
}

.image-container--reveal {
  content: '';
  background-color: rgb(var(--rgbAccent));
  position: absolute;
  inset: 0;
  transform: scale3d(0, 1, 1);
  transform-origin: left;
  z-index: 16;
  animation: image-reveal 1.8s var(--bezierFastoutSlowin);
}

.image__element-wrapper {
  opacity: 0;
  transition: none;
  transform: none;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
}

.image__element-wrapper--reveal {
  opacity: 0;
  transition: opacity var(--durationM) ease var(--delay);
}

.image__element-wrapper--in-viewport {
  opacity: 1;
}

.image__placeholder {
  width: 100%;
  height: auto;
  transition: opacity var(--durationM) ease var(--delay);
  pointer-events: none;
  display: block;
  position: relative;
  z-index: 1;
  opacity: 1;
  grid-column: 1;
  grid-row: 1;
}

.image__placeholder--loaded {
  opacity: 0;
}

.image__element {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0;
  grid-column: 1;
  grid-row: 1;
}

.image__element--loaded {
  opacity: 1;
}

.image__button {
  opacity: 0;
  top: var(--spaceM);
  left: var(--spaceM);
  height: 32px;
  color: rgb(var(--rgbWhite));
  padding: 0 8px 0 2px
}

.image__button::after {
    background-color: rgb(var(--rgbBlack) / 0.8);
  }

.image__button--entered {
  opacity: 1;
}

.section {
  padding-right: var(--space5XL);
  padding-left: calc(var(--spaceXL) * 2);
  outline: none
}
@media (min-width: 2080px) {
    .section {
      padding-left: var(--space5XL);
    }
  }
@media (max-width: 1024px) {
    .section {
      padding-left: calc(var(--space4XL) + var(--space3XL));
    }
  }
@media (max-width: 696px) {
    .section {
      padding-right: var(--spaceL);
      padding-left: var(--spaceL);
    }
  }
@media (max-width: 696px), (max-height: 696px) {
    .section {
      padding-left: var(--spaceOuter);
      padding-right: var(--spaceOuter);
    }
  }
@media (max-width: 820px) and (max-height: 420px) {
    .section {
      padding-left: var(--space4XL);
      padding-right: var(--space4XL);
    }
  }

.heading {
  display: block;
  line-height: var(--lineHeightTitle);
  color: var(--colorTextTitle);
}

.heading--level-0 {
  letter-spacing: -0.006em;
  font-size: var(--fontSizeH0);
}

.heading--level-1 {
  letter-spacing: -0.005em;
  font-size: var(--fontSizeH1);
}

.heading--level-2 {
  font-size: var(--fontSizeH2);
  letter-spacing: -0.003em;
}

.heading--level-3 {
  font-size: var(--fontSizeH3);
}

.heading--level-4 {
  font-size: var(--fontSizeH4);
}

.heading--align-auto {
  text-align: inherit;
}

.heading--align-start {
  text-align: start;
}

.heading--align-center {
  text-align: center;
}

.heading--weight-regular {
  font-weight: var(--fontWeightRegular);
}

.heading--weight-medium {
  font-weight: var(--fontWeightMedium);
}

.heading--weight-bold {
  font-weight: var(--fontWeightBold);
}

.text {
  line-height: var(--lineHeightBody);
  color: var(--colorTextBody);
}

.text--size-s {
  font-size: var(--fontSizeBodyS);
}

.text--size-m {
  font-size: var(--fontSizeBodyM);
}

.text--size-l {
  font-size: var(--fontSizeBodyL);
}

.text--size-xl {
  font-size: var(--fontSizeBodyXL);
}

.text--align-auto {
  text-align: inherit;
}

.text--align-start {
  text-align: start;
}

.text--align-center {
  text-align: center;
}

.text--weight-auto {
  font-weight: inherit;
}

.text--weight-regular {
  font-weight: var(--fontWeightRegular);
}

.text--weight-medium {
  font-weight: var(--fontWeightMedium);
}

.text--weight-bold {
  font-weight: var(--fontWeightBold);
}

.text--secondary {
  color: var(--colorTextLight);
}

.project {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.project__section {
  width: 100%;
  position: relative;
  display: grid;
  place-content: stretch;
}

.project__section--full-height {
  min-height: 100vh;
}

.project__section--light {
  background: rgb(var(--rgbBackgroundLight));
}

.project__section-inner {
  --section-padding: calc(var(--space5XL) + var(--space3XL));

  grid-area: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
  position: relative
}

@media (max-width: 1680px) {
    .project__section-inner {
      --section-padding: var(--space5XL);
    }
  }

@media (max-width: 1024px) {
    .project__section-inner {
      --section-padding: var(--space3XL);
      height: auto;
    }
  }

@media (max-width: 696px) {
    .project__section-inner {
      --section-padding: var(--space2XL);
    }
  }

.project__section-inner--first {
  padding-bottom: 0;
}

.project__section-background {
  grid-area: 1 / 1;
  display: grid;
  place-content: stretch;
}

.project__section-background img {
  object-fit: cover;
  justify-self: stretch;
  align-self: stretch;
}

.project__section-background::after {
  --background1: rgb(var(--rgbBackground) / 1);
  --background2: rgb(var(--rgbBackground) / var(--opacity));
  --background3: rgb(var(--rgbBackground) / 0);

  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
      var(--background1) 0%,
      var(--background2) 2%,
      var(--background3) 1%
    ),
    linear-gradient(var(--background3) 0%, var(--background2) 0%, var(--background1) 0%);
}

.project__background-image {
  --image-height: 800px;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: var(--image-height);
  overflow: hidden;
  opacity: 0;
  transition: opacity 2s ease;
  transform: translateZ(0);
}

.project__background-image--entered {
  opacity: 1;
}

.project__background-image-element {
  z-index: 0;
  position: absolute;
  inset: 0;
  height: var(--image-height);
  overflow: hidden;
  will-change: transform;
  display: grid
}

@media (prefers-reduced-motion: no-preference) {
    .project__background-image-element {
      transform: translate3d(0, var(--offset), 0);
    }
  }

.project__background-scrim {
  --background1: rgb(var(--rgbBackground) / var(--opacity));
  --background2: rgb(var(--rgbBackground) / 0.5);

  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, var(--background1), var(--background2));
}

.project__header {
  padding-top: calc(var(--space5XL) + var(--space3XL));
  padding-bottom: 0
}

@media (max-width: 1680px) {
    .project__header {
      padding-top: var(--space5XL);
      padding-bottom: var(--space2XL);
    }
  }

@media (max-width: 1024px) {
    .project__header {
      padding-top: var(--space4XL);
      padding-bottom: 0;
    }
  }

@media (max-width: 696px) {
    .project__header {
      padding-top: var(--space5XL);
      padding-bottom: var(--spaceXL);
    }
  }

.project__header-content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: var(--space4XL);
  gap: var(--space4XL);
  max-width: var(--maxWidthL)
}

@media (max-width: 2080px) {
    .project__header-content {
      grid-template-columns: 1fr 400px;
    }
  }

@media (max-width: 1200px) {
    .project__header-content {
      grid-template-columns: 1fr 200px;
      grid-gap: var(--space2XL);
      gap: var(--space2XL);
    }
  }

@media (max-width: 1024px) {
    .project__header-content {
      grid-template-columns: 100%;
      grid-gap: var(--spaceXL);
      gap: var(--spaceXL);
    }
  }

@keyframes project-fade-slide {
  0% {
    opacity: 0;
    transform: translate3d(0, var(--space3XL), 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.project__details {
  position: relative
}

@media (max-width: 1024px) {
    .project__details:only-child {
      grid-column: 1;
    }
  }

.project__title {
  margin-bottom: 0.5em
}

@media (prefers-reduced-motion: no-preference) {
    .project__title {
      opacity: 0;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .project__title--entered {
      animation: project-fade-slide 1.4s var(--bezierFastoutSlowin) var(--initDelay)
        forwards;
    }
  }

.project__description {
  padding-bottom: var(--spaceL)
}

@media (prefers-reduced-motion: no-preference) {
    .project__description {
      opacity: 0;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .project__description--entered {
      animation: project-fade-slide 1.4s var(--bezierFastoutSlowin)
        calc(var(--initDelay) + 100ms) forwards;
    }
  }

.project__link-button {
  padding-left: var(--spaceXS)
}

@media (prefers-reduced-motion: no-preference) {
    .project__link-button {
      opacity: 0;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .project__link-button--entered {
      animation: project-fade-slide 1.4s var(--bezierFastoutSlowin)
        calc(var(--initDelay) + 200ms) forwards;
    }
  }

.project__meta {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: var(--spaceM);
}

.project__meta-item {
  padding: var(--spaceL) 0;
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightRegular);
  border-top: 1px solid rgb(var(--rgbText) / 0.2)
}

@media (prefers-reduced-motion: no-preference) {
    .project__meta-item {
      opacity: 0;
    }
  }

@media (max-width: 696px) {
    .project__meta-item {
      padding: var(--spaceM) 0;
    }
  }
.project__meta-title {
  padding: var(--spaceS) 0;
  font-size: var(--fontSizeBodyL);
  font-weight: var(--fontWeightBold)
}
@media (prefers-reduced-motion: no-preference) {
    .project__meta-title {
      opacity: 0;
    }
  }
@media (max-width: 696px) {
    .project__meta-title {
      padding: var(--spaceM) 0;
    }
  }
.project__meta-item:last-child {
  border-bottom: 1px solid rgb(var(--rgbText) / 0.2);
}

@media (prefers-reduced-motion: no-preference) {
    .project__meta-item--entered {
      animation: project-fade-slide 1.5s var(--bezierFastoutSlowin) var(--delay) forwards;
    }
  }

.project__image {
  position: relative;
  display: flex;
  align-items: flex-start;
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  width: 100%;

  border-radius: 10px;
}
img {
  border-radius: 10px;
}

.project__image .image {
  width: 100%;
  border-radius: 10px;
}

.project__section-content {
  max-width: var(--maxWidthL);
  width: 100%;
  display: grid;
  grid-gap: var(--space4XL);
  gap: var(--space4XL)
}

@media (max-width: 1680px) {
    .project__section-content {
      grid-gap: var(--space3XL);
      gap: var(--space3XL);
    }
  }

.project__section-content--width-l {
  max-width: var(--maxWidthL);
}

.project__section-content--width-xl {
  max-width: var(--maxWidthXL);
}

.project__section-content--width-full {
  max-width: 100%;
}

.project__section-heading {
  margin-bottom: 0.6em;
}

.project__section-text + a,
.project__section-text + .project__section-text {
  margin-top: var(--spaceL);
}

.project__text-row {
  align-self: center;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start
}

@media (max-width: 696px) {
    .project__text-row {
      text-align: left;
      align-items: flex-start;
    }
  }

.project__text-row--width-s {
  max-width: var(--maxWidthS);
}

.project__text-row--width-m {
  max-width: var(--maxWidthM);
}

.project__text-row--width-l {
  max-width: var(--maxWidthL);
}

.project__text-row--no-margin {
  margin-bottom: 0;
}

.project__text-row--stretch {
  width: 100%;
}

.project__text-row--center {
  align-items: center;
  text-align: center;
}

.project__text-row--justify-start {
  justify-self: flex-start;
}

.project__text-row--justify-center {
  justify-self: center;
}

.project__text-row--justify-end {
  justify-self: flex-end;
}

@media (max-width: 696px) {
}

.project__section-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space3XL);
  gap: var(--space3XL);
  margin: 0
}

@media (max-width: 1024px) {
    .project__section-columns {
      grid-template-columns: 1fr;
    }
  }

.project__section-columns--centered {
  align-items: center;
}

.segmented-control {
  --inset: 3px;
}

.segmented-control__options {
  position: relative;
  display: flex;
  padding: var(--inset)
}

.segmented-control__options::before {
    content: '';
    background-color: rgb(var(--rgbText) / 0.16);
    position: absolute;
    inset: 0;
    -webkit-clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%
    );
            clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%
    );
  }

.segmented-control__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--colorTextBody);
  transition-property: color, background;
  transition-duration: var(--durationM);
  transition-timing-function: ease;
  border: 0;
  padding: 0 24px;
  height: 56px;
  font-size: inherit;
  font-family: inherit;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
  outline: none
}

.segmented-control__button::after {
    --offset: -3px;

    content: '';
    position: absolute;
    top: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    left: var(--offset);
  }

.segmented-control__button:focus::after {
    box-shadow: 0 0 0 3px rgb(var(--rgbText));
  }

.segmented-control__button:active::after {
    box-shadow: none;
  }

.segmented-control__button[aria-checked='true'] {
    color: rgb(var(--rgbBackground));
  }

.segmented-control__button::before {
    --inset: 8px;

    content: '';
    position: absolute;
    top: var(--inset);
    bottom: var(--inset);
    left: 0;
    width: 1px;
    background-color: rgb(var(--rgbText) / 0.2);
    transition: opacity var(--durationM) var(--bezierFastoutSlowin);
    opacity: 0;
  }

.segmented-control__button:not([aria-checked='true'])
  + .segmented-control__button:not([aria-checked='true'])::before {
  opacity: 1;
}

.segmented-control__indicator {
  position: absolute;
  top: var(--inset);
  bottom: var(--inset);
  left: 0;
  background-color: var(--colorTextTitle);
  transition-property: width, transform, -webkit-clip-path;
  transition-property: width, transform, clip-path;
  transition-property: width, transform, clip-path, -webkit-clip-path;
  transition-duration: var(--durationM);
  transition-timing-function: var(--bezierFastoutSlowin);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
  transform: translate3d(var(--left), 0, 0);
  width: var(--width);
}

.segmented-control__indicator--last {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 9px), calc(100% - 9px) 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 9px), calc(100% - 9px) 100%, 0 100%);
}

.spr__video {
  width: 120%
}

@media (max-width: 1680px) {
    .spr__video {
      width: 150%;
    }
  }

@media (max-width: 1024px) {
    .spr__video {
      width: 100%;
    }
  }

.spr__earth .earth__viewport {
  position: sticky;
  top: 0;
}

.spr__sidebar-images {
  display: grid;
  grid-template-columns: repeat(6, [col] 1fr);
  align-items: center
}

@media (max-width: 1024px) {
    .spr__sidebar-images {
      padding: 0 80px;
      margin-top: 60px;
    }
  }

@media (max-width: 696px) {
    .spr__sidebar-images {
      padding: 0 20px;
      margin-top: 40px;
    }
  }

.spr__sidebar-image:first-child {
    grid-column: col 1 / span 4;
    grid-row: 1;
    position: relative;
    top: 5%;
    opacity: 0.4;
  }

.spr__sidebar-image:last-child {
    grid-column: col 3 / span 4;
    grid-row: 1;
    position: relative;
    top: -5%;
  }

.prc-dotGroup {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .carousel__dot {
    height: 15px;
    width: 15px;
    margin: 3px;
    border-radius: 50%;
    border: 1px solid grey;
  }
  .carousel__dot--selected {
    background-color: grey;
  }
.home {
  overflow-x: hidden;
}


  .decoder-text::after {
    content: '_';
    visibility: hidden;
  }

.decoder-text__glyph {
  opacity: 0.8;
  font-weight: var(--fontWeightRegular);
  font-family: var(--japaneseFontStack);
  line-height: 0;
}

.intro {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;
}

.intro__text {
  max-width: 1024px;
  width: 100%;
  position: relative;
  top: calc(var(--spaceL) * -1)
}

@media (max-width: 2080px) {
    .intro__text {
      max-width: 920px;
    }
  }

@media (max-width: 1680px) {
    .intro__text {
      max-width: 860px;
    }
  }

@media (max-width: 696px) {
    .intro__text {
      top: calc(var(--space3XL) * -1);
    }
  }

@media (max-width: 400px) {
    .intro__text {
      top: calc(var(--spaceXL) * -1);
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .intro__text {
      top: calc(var(--spaceM) * -1);
    }
  }

.intro__name {
  text-transform: uppercase;
  font-size: calc((24 / 16) * 1rem);
  letter-spacing: 0.3em;
  color: var(--colorTextBody);
  margin-bottom: var(--space2XL);
  margin-top: 0;
  font-weight: var(--fontWeightMedium);
  line-height: 1;
  opacity: 0
}

@media (max-width: 2080px) {
    .intro__name {
      font-size: calc((28 / 16) * 1rem);
      margin-bottom: var(--space2XL);
    }
  }

@media (max-width: 1024px) {
    .intro__name {
      font-size: calc((18 / 16) * 1rem);
      margin-bottom: var(--space2XL);
    }
  }

@media (max-width: 696px) {
    .intro__name {
      margin-bottom: 20px;
      letter-spacing: 0.2em;
      white-space: nowrap;
      overflow: hidden;
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .intro__name {
      margin-bottom: 20px;
      margin-top: 30px;
    }
  }

.intro__name--entering {
  animation: fade-in var(--durationL) ease 0.2s forwards;
}

.intro__name--entered {
  animation: fade-in var(--durationL) ease 0.2s forwards;
}

.intro__title {
  display: flex;
  flex-direction: column;
  font-size: calc((140 / 20) * 0.5rem);
  letter-spacing: -0.005em
}

@media (max-width: 2080px) {
    .intro__title {
      font-size: calc((120 / 16)  * 0.65rem);
    }
  }

@media (max-width: 1680px) {
    .intro__title {
      font-size: calc((100 / 16) * 0.6rem);
    }
  }

@media (max-width: 860px) {
    .intro__title {
      font-size: calc((80 / 16) * 1rem);
    }
  }

@media (max-width: 696px) {
    .intro__title {
      font-size: calc((56 / 16) * 1rem);
    }
  }

@media (max-width: 400px) {
    .intro__title {
      font-size: calc((42 / 16) * 1rem);
    }
  }

.intro__title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.intro__title-row--hidden {
  opacity: 0;
}

@keyframes intro-text-reveal {
  0% {
    color: rgb(var(--rgbText) / 0);
  }
  50% {
    color: rgb(var(--rgbText) / 0);
  }
  60% {
    color: var(--colorTextTitle);
  }
  100% {
    color: var(--colorTextTitle);
  }
}

@keyframes intro-text-reveal-mask {
  0% {
    transform: scale3d(0, 1, 1);
    transform-origin: left;
  }
  50% {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }
  51% {
    transform: scale3d(1, 1, 1);
    transform-origin: right;
  }
  100% {
    transform: scale3d(0, 1, 1);
    transform-origin: right;
  }
}

.highlight {
}
.intro__title-word {
  position: relative;
  align-items: center;
  line-height: 1;
  animation-duration: 1.5s;
  animation-delay: var(--delay);
  animation-fill-mode: forwards;
  animation-timing-function: var(--bezierFastoutSlowin);
  color: rgb(var(--rgbText) / 0);
  transition: opacity 0.5s ease 0.4s
}
.intro__title-word::after {
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(var(--rgbAccent));
    animation-duration: 1.5s;
    animation-delay: var(--delay);
    animation-fill-mode: forwards;
    animation-timing-function: var(--bezierFastoutSlowin);
    transform-origin: left;
    transform: scale3d(0, 1, 1);
    position: absolute;
    inset: 0;
    z-index: 1;
    will-change: transform;
  }

.intro__title-word--plus::before {
  margin-right: 10px;
  opacity: 0.4;
}

.intro__title-word--entering {
  animation-name: intro-text-reveal
}

@media (prefers-reduced-motion: no-preference) {
    .intro__title-word--entering::after {
      animation-name: intro-text-reveal-mask;
    }
  }

.intro__title-word--entered {
  color: var(--colorTextTitle)
}

.intro__title-word--entered::after {
    opacity: 1;
    transform: scale3d(0, 0, 1);
    transform-origin: right;
  }

.intro__title-word--exiting {
  color: var(--colorTextTitle);
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

@keyframes intro-line {
  0% {
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

.intro__title-line {
  content: '';
  height: 2px;
  background: rgb(var(--rgbText) / 0.3);
  width: 120%;
  display: flex;
  margin-left: 20px;
  animation-duration: 0.8s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--bezierFastoutSlowin);
  transform-origin: left;
  opacity: 0;
}

.intro__title-line--entering {
  animation-name: fade-in
}

@media (prefers-reduced-motion: no-preference) {
    .intro__title-line--entering {
      animation-name: intro-line;
    }
  }

.intro__title-line--entered {
  transform: scaleX(1);
  opacity: 1;
}

@keyframes intro-scroll-indicator {
  0% {
    transform: translate3d(-1px, 0, 0);
    opacity: 0;
  }
  20% {
    transform: translate3d(-1px, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(-1px, 8px, 0);
    opacity: 0;
  }
}

.intro__scroll-indicator {
  border: 2px solid rgb(var(--rgbText) / 0.4);
  border-radius: 20px;
  width: 26px;
  height: 38px;
  position: fixed;
  bottom: 64px;
  transition-property: opacity;
  transition-duration: var(--durationL);
  transition-timing-function: ease;
  opacity: 0
}

.intro__scroll-indicator::before {
    content: '';
    height: 7px;
    width: 2px;
    background: rgb(var(--rgbText) / 0.4);
    border-radius: 4px;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-1px);
  }

@media (prefers-reduced-motion: no-preference) {
    .intro__scroll-indicator {
      transition-property: opacity, transform;
    }

    .intro__scroll-indicator::before {
      animation: intro-scroll-indicator 2s ease infinite;
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .intro__scroll-indicator {
      display: none;
    }
  }

.intro__scroll-indicator--entered {
  opacity: 1;
}

.intro__scroll-indicator--hidden {
  opacity: 0
}

@media (prefers-reduced-motion: no-preference) {
    .intro__scroll-indicator--hidden {
      transform: translate3d(0, 20px, 0);
    }
  }

@keyframes intro-mobile-scroll-indicator {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.intro__mobile-scroll-indicator {
  position: fixed;
  bottom: 20px;
  opacity: 0;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.8, 0.1, 0.27, 1);
  transition-duration: var(--durationM)
}

@media (prefers-reduced-motion: no-preference) {
    .intro__mobile-scroll-indicator {
      animation-name: intro-mobile-scroll-indicator;
      transition-property: opacity, transform;
      transform: translate3d(0, 20px, 0);
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .intro__mobile-scroll-indicator {
      display: none;
    }
  }

.intro__mobile-scroll-indicator--entered {
  opacity: 1;
}

.intro__mobile-scroll-indicator--hidden {
  opacity: 0;
  transform: none;
}

.intro__mobile-scroll-indicator svg {
  stroke: rgb(var(--rgbText) / 0.5);
}

.displacement-sphere {
  position: absolute;
  width: 6vw;
  inset: 0;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 3s;
  transition-timing-function: var(--bezierFastoutSlowin);
}

.displacement-sphere--entering,
.displacement-sphere--entered {
  opacity: 1;
}

.divider {
  position: relative;
  width: var(--lineWidth);
  height: var(--lineHeight);
}

.divider__line {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgb(var(--rgbPrimary));
  opacity: 1;
  transition-property: opacity;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-delay: var(--collapseDelay);
  transform-origin: left center;
  transform: scaleX(1)
}

@media (prefers-reduced-motion: no-preference) {
    .divider__line {
      transition-property: transform, opacity;
    }
  }

.divider__line--collapsed {
  opacity: 0;
  transform: scaleX(0);
}

.divider__notch {
  content: '';
  background-color: rgb(var(--rgbPrimary));
  position: absolute;
  transition-property: opacity;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-delay: var(--collapseDelay);
  opacity: 1;
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 10px 100%);
          clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 10px 100%);
  width: var(--notchWidth);
  height: var(--notchHeight);
  top: var(--lineHeight)
}

@media (prefers-reduced-motion: no-preference) {
    .divider__notch {
      transition-property: opacity, -webkit-clip-path;
      transition-property: clip-path, opacity;
      transition-property: clip-path, opacity, -webkit-clip-path;
    }
  }

.divider__notch--collapsed {
  opacity: 0;
  -webkit-clip-path: polygon(0 0, 0 0, 10px 100%, 10px 100%);
          clip-path: polygon(0 0, 0 0, 10px 100%, 10px 100%);
}

.profile {
  width: 100vw;
  min-height: 100vh;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center
}

@media (max-width: 1024px) {
    .profile {
      padding-top: 50px;
      padding-right: 80px;
      padding-left: 160px;
      height: auto;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

@media (max-width: 696px) {
    .profile {
      margin-top: 0;
      padding-top: 90px;
      padding-left: 25px;
      padding-right: 25px;
      overflow-x: hidden;
    }
  }

@media (max-width: 696px), (max-height: 696px) {
    .profile {
      padding-right: var(--spaceOuter);
      padding-left: var(--spaceOuter);
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .profile {
      padding-right: var(--space4XL);
      padding-left: var(--space4XL);
    }
  }

.profile__content {
  display: grid;
  grid-template-columns: 1fr 50%;
  grid-column-gap: var(--space2XL);
  max-width: var(--maxWidthL);
  width: 100%
}

@media (max-width: 1024px) {
    .profile__content {
      max-width: 600px;
      grid-template-columns: 100%;
    }
  }

.profile__column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  transform: translate3d(0, 0, 0);
}

.profile__title {
  white-space: nowrap;
  margin-bottom: var(--spaceL);
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationM);
}

.profile__title--entered {
  opacity: 1;
}

.profile__description {
  margin-bottom: var(--spaceXL);
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationL);
}

.profile__description--entered {
  opacity: 1;
}

.profile__tag {
  margin-top: 220px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: var(--space4XL) 1fr;
  grid-gap: 12px;
  gap: 12px;
  align-items: center
}

@media (max-width: 1024px) {
    .profile__tag {
      margin-top: 30px;
    }
  }

.profile__tag-text {
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightMedium);
  color: rgb(var(--rgbPrimary));
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s
}

@media (prefers-reduced-motion: no-preference) {
    .profile__tag-text {
      transition-property: opacity, transform;
      transform: translate3d(calc(var(--spaceM) * -1), 0, 0);
    }
  }

.profile__tag-text--entered {
  transform: none;
  opacity: 1;
}

.profile__image-wrapper {
  position: relative;
  width: 100%;
}

.profile__svg {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate3d(50%, -20%, 0);
  height: 100%;
  z-index: 32;
  opacity: 0;
  transition: opacity var(--durationM) ease var(--durationL);
  fill: var(--colorTextTitle);
}

.profile__svg--entered {
  opacity: 1;
}

.profile__button {
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationL);
}

.profile__button--entered {
  opacity: 1;
}

.model {
  position: relative;
  opacity: 0;
}

.model--loaded {
  animation: fade-in 1s ease forwards var(--delay);
}

.model__canvas {
  position: absolute;
  inset: 0;
}

.project-summary {
  height: 100vh;
  width: 100%;
  max-height: 1080px;
  padding-bottom: var(--spaceL);
  margin: 120px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none
}

@media (max-width: 2080px) {
    .project-summary {
      margin: 40px 0;
    }
  }

@media (max-width: 1024px) {
    .project-summary {
      height: auto;
      margin: 60px 0;
    }
  }

@media (max-width: 696px) {
    .project-summary {
      padding-bottom: var(--space4XL);
      margin-bottom: 0;
    }
  }

.project-summary--first {
  margin-top: 0
}

@media (max-width: 1024px) {
    .project-summary--first {
      margin-top: 0;
    }
  }

.project-summary__content {
  width: 100%;
  max-width: var(--maxWidthL);
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-column-gap: var(--space2XL)
}

.project-summary--alternate .project-summary__content {
    grid-template-columns: 1fr 40%
}

@media (max-width: 1680px) {

.project-summary__content {
    .project-summary__content {
      grid-template-columns: 1fr 1fr;
    }
}
  }

@media (max-width: 1024px) {

.project-summary--alternate .project-summary__content {
      grid-template-columns: 100%
}
  }

@media (max-width: 1024px) {
    .project-summary__content {
      grid-template-columns: 100%;
      flex-direction: column-reverse;
      height: auto;
    }
  }

.project-summary__details {
  z-index: 1;
  position: relative
}

@media (max-width: 1024px) {
    .project-summary__details {
      flex: 0 0 auto;
      max-width: 410px;
      grid-row: 2;
      grid-column: 1;
      justify-self: center;
    }
  }

.project-summary__preview {
  flex: 0 1 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.project-summary__model {
  --width: 180%;
  --aspectRatio: 10 / 12;

  min-width: var(--width);
  padding-bottom: calc(var(--aspectRatio) * var(--width));
}

.project-summary__model--laptop {
  top: 6%;
  left: 14%
}

@media (max-width: 2080px) {
    .project-summary__model--laptop {
      --width: 180%;
    }
  }

@media (max-width: 1024px) {
    .project-summary__model--laptop {
      --width: 140%;

      top: 10%;
      left: 0;
    }
  }

.project-summary__model--phone {
  --width: 116%;
  --aspectRatio: 10 / 7;

  top: 5%;
  left: -10%
}

@media (max-width: 2080px) {
    .project-summary__model--phone {
      --width: 108%;

      left: 0;
    }
  }

@media (max-width: 1024px) {
    .project-summary__model--phone {
      --width: 108%;

      left: 0;
    }
  }

.project-summary__svg {
  opacity: 0;
  transition: opacity 1200ms ease 1400ms;
  fill: var(--colorTextTitle);
}

.project-summary__svg--entered {
  opacity: var(--opacity);
}

.project-summary__svg--laptop {
  position: absolute;
  width: 100%;
  right: -36%;
  bottom: 24%;
  transform: translateY(50%)
}

@media (max-width: 1024px) {
    .project-summary__svg--laptop {
      width: 80%;
      right: -10%;
      bottom: 18%;
    }
  }

@media (max-width: 696px) {
    .project-summary__svg--laptop {
      right: 0;
    }
  }

.project-summary__svg--phone {
  position: absolute;
  bottom: 23%;
  transform: translateY(50%);
  width: 100%;
  transition-delay: 800ms
}

@media (max-width: 1024px) {
    .project-summary__svg--phone {
      width: 80%;
      bottom: 23%;
    }
  }

.project-summary__index {
  position: relative;
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-gap: var(--spaceM);
  gap: var(--spaceM);
  align-items: center;
  margin-bottom: var(--spaceXL);
}

.project-summary__index-number {
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightMedium);
  color: rgb(var(--rgbPrimary));
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s
}

@media (prefers-reduced-motion: no-preference) {
    .project-summary__index-number {
      transition-property: transform, opacity;
      transform: translateX(calc(var(--spaceM) * -1));
    }
  }

.project-summary__index-number--entered {
  transform: translateX(0);
  opacity: 1;
}

.project-summary__title {
  margin-bottom: var(--spaceL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationM);
  opacity: 0
}

@media (prefers-reduced-motion: no-preference) {
    .project-summary__title {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }

.project-summary__title--entered {
  transform: none;
  opacity: 1;
}

.project-summary__description {
  margin-bottom: var(--spaceXL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationL);
  opacity: 0
}

@media (prefers-reduced-motion: no-preference) {
    .project-summary__description {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }

.project-summary__description--entered {
  transform: none;
  opacity: 1;
}

.project-summary__button {
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationXL);
  transform: translate3d(0, var(--spaceL), 0);
  opacity: 0
}

@media (prefers-reduced-motion: no-preference) {
    .project-summary__button {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }

.project-summary__button--entered {
  transform: none;
  opacity: 1;
}

.clientsClassSection {
  width: 100vw;
  min-height: 70vh;
  padding-bottom: 40px;
  margin-top: 80px;
  display: flex;
  justify-content: center
}
@media (max-width: 1024px) {
    .clientsClassSection {
      padding-top: 50px;
      padding-right: 80px;
      padding-left: 160px;
      height: auto;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
@media (max-width: 696px), (max-height: 696px) {
    .clientsClassSection {
      padding-right: var(--spaceOuter);
      padding-left: var(--spaceOuter);
    }
  }
@media (max-width: 820px) and (max-height: 420px) {
    .clientsClassSection {
      padding-right: var(--space4XL);
      padding-left: var(--space4XL);
    }
  }

.clientBox {
    margin: 2em 2em;
    outline: none;
    cursor: default;
    display: flex;
    padding: 48px;
    align-items: center;
    flex-direction: column;
    justify-content: top;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition-duration: 300ms;
    background-color: var(--clientTileColor);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
    transition-timing-function: var(--bezierFastoutSlowin)
  }
  @media (max-width: 1024px) {
      .clientBox {
        width: 90%;
        margin: 1em;  padding: 48px 20px;
  
      }
    }
  .clientBox::after {
    position: absolute;
    inset: 0;
  }
  .clientBox:hover {
    transform: scale(1.05);
  }
  
  .clientBox:active {
    transform: scale(1);
  }
.clientBoxSection {
  display: flex;
  width: 100%;
  margin-top: 40px
}
@media (max-width: 1024px) {
    .clientBoxSection {
      height: auto;
      margin-top: 0px;

      grid-row: 1;
      flex-direction: column;
      display: inline-flex;
      margin-bottom: 40px;
    }
  }


.productionStrategy{display:flex;align-items:center;justify-content:center;flex-direction:column;width:100%;margin:0 0 80px 0;padding:1em}.productionStrategy h1{font-size:32px}@media(max-width: 1024px){.productionStrategy{width:100%}.productionStrategy h1{font-size:28px}}.productionStrategy .productionStrategy_decoderText{width:100%;margin:120px;font-size:3em}@media(max-width: 1024px){.productionStrategy .productionStrategy_decoderText{font-size:2em;margin:64px 32px}}
.productionStrategyTiles{display:flex}@media(max-width: 1024px){.productionStrategyTiles{flex-direction:column}}.productionStrategyTiles .productionStrategyIndividualTile{width:320px;margin:64px 64px;display:flex;align-items:center;flex-direction:column}.productionStrategyTiles .productionStrategyIndividualTile .productionStrategySvg{margin:64px 0px;transition-timing-function:var(--bezierFastoutSlowin);transition-duration:var(--durationM);height:150px}.productionStrategyTiles .productionStrategyIndividualTile .productionStrategySvg:hover{transform:scale(1.1);fill:rgb(var(--rgbPrimary))}
.partner_section{align-items:center;display:flex;flex-direction:column;justify-content:center;margin-bottom:80px}@media(max-width: 1024px),(max-height: 696px){.partner_section{margin-bottom:32px}}.partner_section .partner_section_logo_section{display:flex;justify-content:center;align-items:center}@media(max-width: 696px),(max-height: 696px){.partner_section .partner_section_logo_section{flex-direction:column}}.partner_section .partner_section_logo_section .partner_section_logo_sub_section{align-items:center;justify-content:center}@media(max-width: 1024px),(max-height: 696px){.partner_section .partner_section_logo_section .partner_section_logo_sub_section{display:flex;flex-direction:column}}@media(max-width: 696px),(max-height: 696px){.partner_section .partner_section_logo_section .partner_section_logo_sub_section{display:flex;flex-direction:column}}.partner_section .partner_section_logo_section .partner_section_logo_sub_section .partner_section_brand_logo:hover{transform:scale(1.1);fill:rgb(var(--rgbPrimary))}.partner_section .partner_section_logo_section .partner_section_logo_sub_section .partner_section_brand_logo{height:80px;width:200px;align-items:center;margin:8px 32px;fill:var(--colorTextBody);transition-timing-function:var(--bezierFastoutSlowin);transition-duration:var(--durationM)}@media(max-width: 696px),(max-height: 696px){.partner_section .partner_section_logo_section .partner_section_logo_sub_section .partner_section_brand_logo{width:180px}}
.our-services-section{padding-top:60px;background-color:#111}@media(max-width: 696px){.our-services-section{display:flex;flex-direction:column;width:100%;align-items:center}}.container{margin-left:auto;margin-right:auto;margin:100px}@media(max-width: 1024px){.container{margin:32px}}.row{display:flex;flex-wrap:wrap;margin-left:-15px;margin-right:-15px}.col-md-6{flex:0 0 50%;max-width:50%;padding-right:15px}.axil-btn{display:inline-block;padding:15px 30px;border:none;border-radius:5px;font-size:16px;font-weight:600;text-align:center;text-transform:uppercase;color:#fff}.btn-fill-white{color:#000;background-color:#fff;border:1px solid #fff}.brand-logo img{max-width:100%;height:auto}
.project-services__section--full-height {
  min-height: 40vh;
}

.project-services__section--light {
  background: rgb(var(--rgbBackgroundLight));
}

.project-services__section-inner {
  grid-area: 1 / 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  
}



.project-services__section-background {
  grid-area: 1 / 1;
  display: grid;
  place-content: stretch;
}

.project-services__section-background img {
  object-fit: cover;
  justify-self: stretch;
  align-self: stretch;
}

.project-services__section-background::after {
  --background1: rgb(var(--rgbBackground) / 1);
  --background2: rgb(var(--rgbBackground) / var(--opacity));
  --background3: rgb(var(--rgbBackground) / 0);

  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
      var(--background1) 0%,
      var(--background2) 2%,
      var(--background3) 1%
    ),
    linear-gradient(var(--background3) 0%, var(--background2) 0%, var(--background1) 0%);
}

.project-services__background-image {
  --image-height: 800px;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: var(--image-height);
  overflow: hidden;
  opacity: 0;
  transition: opacity 2s ease;
  transform: translateZ(0);
}

.project-services__background-image--entered {
  opacity: 1;
}

.project-services__background-image-element {
  z-index: 0;
  position: absolute;
  inset: 0;
  height: var(--image-height);
  overflow: hidden;
  will-change: transform;
  display: grid
}

@media (prefers-reduced-motion: no-preference) {
    .project-services__background-image-element {
      transform: translate3d(0, var(--offset), 0);
    }
  }

.project-services__background-scrim {
  --background1: rgb(var(--rgbBackground) / var(--opacity));
  --background2: rgb(var(--rgbBackground) / 0.5);

  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, var(--background1), var(--background2));
}

.project-services__header {
  padding-top: calc(var(--space2XL));
  padding-bottom: 32px;

 
}

.project-services__header-content {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 250px;
  grid-gap: var(--space3XL);
  gap: var(--space3XL);

  
}

@keyframes project-services-fade-slide {
  0% {
    opacity: 0;
    transform: translate3d(0, var(--space3XL), 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.project-services__details {
  position: relative
}

@media (max-width: 1024px) {
    .project-services__details:only-child {
      grid-column: 1;
    }
  }

.project-services__title {
  margin-bottom: 0.5em
}

@media (prefers-reduced-motion: no-preference) {
    .project-services__title {
      opacity: 0;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .project-services__title--entered {
      animation: project-services-fade-slide 1.4s var(--bezierFastoutSlowin)
        var(--initDelay) forwards;
    }
  }

.project-services__description {
  padding-bottom: var(--spaceL)
}

@media (prefers-reduced-motion: no-preference) {
    .project-services__description {
      opacity: 0;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .project-services__description--entered {
      animation: project-services-fade-slide 1.4s var(--bezierFastoutSlowin)
        calc(var(--initDelay) + 100ms) forwards;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .project-services__link-button {
      opacity: 0;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .project-services__link-button--entered {
      animation: project-services-fade-slide 1.4s var(--bezierFastoutSlowin)
        calc(var(--initDelay) + 200ms) forwards;
    }
  }

.project-services__meta {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: var(--spaceM);
}

.project-services__meta-item {
  padding: var(--spaceM) 0;
  font-weight: var(--fontWeightRegular);
  border-top: 1px solid rgb(var(--rgbText) / 0.2)
}

@media (prefers-reduced-motion: no-preference) {
    .project-services__meta-item {
      opacity: 0;
    }
  }

@media (max-width: 696px) {
    .project-services__meta-item {
      padding: var(--spaceM) 0;
    }
  }

.project-services__meta-item:last-child {
  border-bottom: 1px solid rgb(var(--rgbText) / 0.2);
}

@media (prefers-reduced-motion: no-preference) {
    .project-services__meta-item--entered {
      animation: project-services-fade-slide 1.5s var(--bezierFastoutSlowin) var(--delay)
        forwards;
    }
  }

.project-services__image {
  position: relative;
  display: flex;
  align-items: flex-start;
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  width: 100%;

  border-radius: 10px;
}
img {
  border-radius: 10px;
}

.project-services__image .image {
  width: 100%;
  border-radius: 10px;
}

.project-services__section-content {
  max-width: var(--maxWidthL);
  width: 100%;
  display: grid;
  grid-gap: var(--space4XL);
  gap: var(--space4XL)
}

@media (max-width: 1680px) {
    .project-services__section-content {
      grid-gap: var(--space3XL);
      gap: var(--space3XL);
    }
  }

.project-services__section-content--width-l {
  max-width: var(--maxWidthL);
}

.project-services__section-content--width-xl {
  max-width: var(--maxWidthXL);
}

.project-services__section-content--width-full {
  max-width: 100%;
}

.project-services__section-heading {
  margin-bottom: 0.6em;
}

.project-services__section-text + a,
.project-services__section-text + .project-services__section-text {
  margin-top: var(--spaceL);
}

.project-services__text-row {
  align-self: center;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start
}

@media (max-width: 696px) {
    .project-services__text-row {
      text-align: left;
      align-items: flex-start;
    }
  }

.project-services__text-row--width-s {
  max-width: var(--maxWidthS);
}

.project-services__text-row--width-m {
  max-width: var(--maxWidthM);
}

.project-services__text-row--width-l {
  max-width: var(--maxWidthL);
}

.project-services__text-row--no-margin {
  margin-bottom: 0;
}

.project-services__text-row--stretch {
  width: 100%;
}

.project-services__text-row--center {
  align-items: center;
  text-align: center;
}

.project-services__text-row--justify-start {
  justify-self: flex-start;
}

.project-services__text-row--justify-center {
  justify-self: center;
}

.project-services__text-row--justify-end {
  justify-self: flex-end;
}

@media (max-width: 696px) {
}

.project-services__section-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space3XL);
  gap: var(--space3XL);
  margin: 0
}

@media (max-width: 1024px) {
    .project-services__section-columns {
      grid-template-columns: 1fr;
    }
  }

.project-services__section-columns--centered {
  align-items: center;
}

.project-add-banner,.project-add-banner--reverse{width:100%;display:flex;align-items:center;margin-bottom:60px;background-color:rgb(var(--rgbAccent));margin-bottom:200px;margin-top:120px;border-radius:30px;padding:0}@media(max-width: 1024px){.project-add-banner,.project-add-banner--reverse{flex-direction:column-reverse}}.thumbnail{flex:1 1;background-color:#fff;display:flex;justify-content:center;height:80vh;max-width:100%;border-radius:0 30px 30px 0;align-items:center;overflow:hidden}.thumbnail img{height:400px;width:600px;transition:all .8s ease-in-out;object-fit:cover;border-radius:0 30px 30px 0}.thumbnail img:hover{transform:scale(1.1)}@media(max-width: 1024px){.thumbnail{height:100%;border-radius:30px;width:100%;margin:0px}.thumbnail img{border-radius:30px;width:100%}}@media(max-width: 696px){.thumbnail img{height:300px;width:300px}}.content{flex:1 1;margin:0px}@media(max-width: 1024px){.content{margin:0px;margin-top:40px}}.project-add-banner--reverse{flex-direction:row-reverse}.project-add-banner--reverse .thumbnail{border-radius:30px 0px 0px 30px}.project-add-banner--reverse .thumbnail img{border-radius:30px;width:100%;height:100%}@media(max-width: 1024px){.project-add-banner--reverse .thumbnail{height:100%;border-radius:30px;width:100%;margin:0px}.project-add-banner--reverse .thumbnail img{border-radius:30px}}@media(max-width: 696px){.project-add-banner--reverse .thumbnail img{height:300px;width:300px}}@media(max-width: 1024px){.project-add-banner--reverse{flex-direction:column-reverse}}

