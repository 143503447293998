.partner_section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;

  @media (--mediaTablet), (max-height: 696px) {
    & {
      margin-bottom: 32px;
    }
  }
  .partner_section_logo_section {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (--mediaMobile), (max-height: 696px) {
      & {
        flex-direction: column;
      }
    }
    .partner_section_logo_sub_section {
      align-items: center;
      justify-content: center;

      @media (--mediaTablet), (max-height: 696px) {
        & {
          display: flex;
          flex-direction: column;
        }
      }
      @media (--mediaMobile), (max-height: 696px) {
        & {
          display: flex;

          flex-direction: column;
        }
      }
      .partner_section_brand_logo:hover {
        transform: scale(1.1);
        fill: rgb(var(--rgbPrimary));
      }
      .partner_section_brand_logo {
        height: 80px;
        width: 200px;
        align-items: center;

        margin: 8px 32px;
        fill: var(--colorTextBody);
        transition-timing-function: var(--bezierFastoutSlowin);
        transition-duration: var(--durationM);

        @media (--mediaMobile), (max-height: 696px) {
          & {
            width: 180px;
          }
        }

        //background-color: aqua;
      }
    }
  }
}
