.productionStrategyTiles {
  display: flex;

  @media (--mediaTablet) {
    & {
      flex-direction: column;
    }
  }
  .productionStrategyIndividualTile {
    width: 320px;
    margin: 64px 64px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .productionStrategySvg {
      margin: 64px 0px;
      transition-timing-function: var(--bezierFastoutSlowin);
      transition-duration: var(--durationM);

      height: 150px;
    }

    .productionStrategySvg:hover {
      transform: scale(1.1);
      fill: rgb(var(--rgbPrimary));
    }
  }
}
