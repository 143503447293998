.clientsClassSection {
  width: 100vw;
  min-height: 70vh;
  padding-bottom: 40px;
  margin-top: 80px;
  display: flex;
  justify-content: center;

  @media (--mediaTablet) {
    & {
      padding-top: 50px;
      padding-right: 80px;
      padding-left: 160px;
      height: auto;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }


  

  @media (--mediaMobile), (max-height: 696px) {
    & {
      padding-right: var(--spaceOuter);
      padding-left: var(--spaceOuter);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      padding-right: var(--space4XL);
      padding-left: var(--space4XL);
    }
  }
}
