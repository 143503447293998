.clientBoxSection {
  display: flex;
  width: 100%;
  margin-top: 40px;

  @media (--mediaTablet) {
    & {
      height: auto;
      margin-top: 0px;

      grid-row: 1;
      flex-direction: column;
      display: inline-flex;
      margin-bottom: 40px;
    }
  }
}

