.segmented-control {
  --inset: 3px;
}

.segmented-control__options {
  position: relative;
  display: flex;
  padding: var(--inset);

  &::before {
    content: '';
    background-color: rgb(var(--rgbText) / 0.16);
    position: absolute;
    inset: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%
    );
  }
}

.segmented-control__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--colorTextBody);
  transition-property: color, background;
  transition-duration: var(--durationM);
  transition-timing-function: ease;
  border: 0;
  padding: 0 24px;
  height: 56px;
  font-size: inherit;
  font-family: inherit;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
  outline: none;

  &::after {
    --offset: -3px;

    content: '';
    position: absolute;
    top: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    left: var(--offset);
  }

  &:focus::after {
    box-shadow: 0 0 0 3px rgb(var(--rgbText));
  }

  &:active::after {
    box-shadow: none;
  }

  &[aria-checked='true'] {
    color: rgb(var(--rgbBackground));
  }

  &::before {
    --inset: 8px;

    content: '';
    position: absolute;
    top: var(--inset);
    bottom: var(--inset);
    left: 0;
    width: 1px;
    background-color: rgb(var(--rgbText) / 0.2);
    transition: opacity var(--durationM) var(--bezierFastoutSlowin);
    opacity: 0;
  }
}

.segmented-control__button:not([aria-checked='true'])
  + .segmented-control__button:not([aria-checked='true'])::before {
  opacity: 1;
}

.segmented-control__indicator {
  position: absolute;
  top: var(--inset);
  bottom: var(--inset);
  left: 0;
  background-color: var(--colorTextTitle);
  transition-property: width, transform, clip-path;
  transition-duration: var(--durationM);
  transition-timing-function: var(--bezierFastoutSlowin);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
  transform: translate3d(var(--left), 0, 0);
  width: var(--width);
}

.segmented-control__indicator--last {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 9px), calc(100% - 9px) 100%, 0 100%);
}
